<template>
  <div class="socialAllowance">
    <el-dialog title="兴村通平台社会化服务记录" :visible.sync="dialogVisible" width="70%" :before-close="clearForm">
      <table-page :data="tableData" :columns="columns" :pagination="false">
        <template slot="orderType" slot-scope="scope">
          <span>{{ scope.row.orderType == 1 ? "全程托管" : "环节托管" }}</span>
        </template>
        <template slot="createTime" slot-scope="scope">
          <span>{{
            scope.row.createTime ? scope.row.createTime.substr(0, 10) : ""
            }}</span>
        </template>
        <template slot="Time" slot-scope="scope">
          <span>{{
            scope.row.startDate ? scope.row.startDate.substr(0, 10) : ""
            }}~{{
            scope.row.endDate ? scope.row.endDate.substr(0, 10) : ""
            }}</span>
        </template>
        <template slot="operation" slot-scope="scope">
          <el-button type="text" size="small" @click="showDetail(scope.row)">详情</el-button>
        </template>
      </table-page>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
      <!-- 托管项目详情 -->
      <el-dialog width="800px" title="需求信息详情" :visible.sync="innerVisible" append-to-body>
        <div class="innerBox">
          <el-form :inline="true" label-width="100px">
            <el-form-item label="托管人:" style="width: 300px">
              {{ innerBox.farmerName }}
            </el-form-item>
            <el-form-item label="托管人电话:">
              {{ innerBox.farmerPhone }}
            </el-form-item>
            <el-form-item label="接管人:" style="width: 300px">
              {{ innerBox.takeOverName }}
            </el-form-item>
            <el-form-item label="接管人电话:">
              {{ innerBox.companyPhone }}
            </el-form-item>
          </el-form>
          <el-form ref="form" label-width="210px">
            <el-form-item label="接管人统一社会信用代码:">{{
              innerBox.companyCode
              }}</el-form-item>
            <el-form-item label="托管类型:">
              {{ innerBox.orderType == 1 ? "全程托管" : "环节托管" }}
            </el-form-item>
            <el-form-item label="作物类型:">
              {{ innerBox.orderContent }}
            </el-form-item>
            <el-form-item label="每季保底产量:">
              {{ innerBox.seasonYield }}
            </el-form-item>
            <el-form-item label="土地面积:">
              {{ innerBox.landArea }}亩
            </el-form-item>
            <el-form-item label="地址:">
              {{ innerBox.areaName }}
            </el-form-item>
            <el-form-item label="总价:">
              {{ innerBox.estimatePrice }}元
            </el-form-item>
            <el-form-item label="其他说明:">
              {{ innerBox.remark }}
            </el-form-item>
            <el-form-item label="产权凭证:">
              <p v-for="(item, index) in innerBox.pzzp" :key="index" class="pdfStyle">
                {{ item.name }}
                <i class="el-icon-download" title="下载" @click="download(item)"></i>
              </p>
              <!-- <el-image
                style="width: 100px; height: 100px"
                :src="item"
                v-for="(item, index) in innerBox.pzzp"
                :key="index"
                :preview-src-list="innerBox.pzzp"
              >
              </el-image> -->
            </el-form-item>
            <el-form-item label="现场照片:">
              <el-image style="width: 100px; height: 100px; margin-right: 5px" :src="item"
                v-for="(item, index) in innerBox.xczp" :key="index" :preview-src-list="innerBox.xczp">
              </el-image>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </el-dialog>

    <el-dialog title="审批通过" :visible.sync="show" width="30%" append-to-body :before-close="clearForm">
      <div class="tongyi">
        <div class="topbox">
          <span>本人承诺购买行为、发票、购机价格等信息真实有效，按相关规定申办补贴。</span>
          <!-- <input type="text" v-model="idea" @input="callApiOnBlur" /> -->
        </div>
        <div class="footerbox">
          <div v-if="show" class="img">
            <vue-qr ref="qrCode" :text="text" :color-dark="black" width="200" height="200"></vue-qr>
          </div>
          <!-- <el-button type="primary" @click="regenerateQRCode()">
          重新生成
        </el-button> -->

          <!-- <img src="../../../assets/acquiesce.png" alt="加载失败" /> -->
          <h1 style="color: #ccc">请使用微信扫描后签字提交</h1>
        </div>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="clearForm">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 社会化服务补贴项目填写 -->
    <div class="subsidyBox">
      <el-form :inline="true" :model="form" label-position="left" :rules="formRules" ref="ruleForm">
        <div class="LoanApplication">
          <!-- 基础信息 -->
          <div class="BasicInformation">
            <div class="title">
              <p>基础信息</p>
            </div>

            <div class="textContent">
              <div class="Left">
                <el-form-item label="申请人：">
                  <el-input :disabled="true" v-model="form.applyUser" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="身份证号：">
                  <el-input :disabled="true" v-model="form.idCard" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="银行账户：">
                  <el-input :disabled="true" v-model="form.bankCard" placeholder=""></el-input>
                </el-form-item>

                <div class="LegalPersonIDCard">
                  <label class="el-form-item__label" style="width: 110px">
                    身份证照片：
                  </label>
                  <el-image :src="
                      baseUrl + 'admin/file/get?ossFilePath=' + userCardFrontImg
                    ">
                    <div slot="error" class="image-slot">
                      <img src="../../assets/icon/imgError.png" />
                    </div>
                  </el-image>
                  <el-image :src="
                      baseUrl + 'admin/file/get?ossFilePath=' + userCardBackImg
                    ">
                    <div slot="error" class="image-slot">
                      <img src="../../assets/icon/imgError.png" />
                    </div>
                  </el-image>
                </div>
              </div>

              <div class="Right">
                <el-form-item label="联系电话：">
                  <el-input v-model="form.phone" :disabled="true" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="地址：">
                  <!-- <el-cascader
              style="width: 190px"
              ref="address"
              v-model="form.areaCode"
              :props="props"
              :options="proviceArr"
              :show-all-levels="false"
              @change="regionChange"
            ></el-cascader> -->
                  <el-input v-model="form.address" placeholder="" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="开户行：">
                  <el-input v-model="form.bankName" :disabled="true" placeholder=""></el-input>
                </el-form-item>
              </div>
            </div>
          </div>

          <!-- 补贴面积 -->
          <div class="SubsidizedArea">
            <div class="title">
              <p>补贴面积</p>
            </div>

            <div class="textContent">
              <div class="Left">
                <el-form-item label="机耕面积：" prop="cultivatedArea">
                  <el-input :disabled="true" v-model="form.cultivatedArea" placeholder="" type="number"></el-input>
                  <div class="unit">亩</div>
                </el-form-item>
                <div class="tips" v-if="aircraftArea2">
                  <span> (其中新村通平台服务{{ aircraftArea2 }}亩)</span>
                  <span style="color: #64addc; padding-left: 20px" @click="detailClick(0)">查看详情</span>
                </div>
                <!-- <p>元</p> -->
                <!-- <span style="padding-top:10px">
            元
          </span>
        <p>{{ BigYearIncome }}</p> -->
              </div>
              <div class="Left">
                <el-form-item label="机种面积：" prop="aircraftArea">
                  <el-input :disabled="true" v-model="form.aircraftArea" type="number" placeholder=""></el-input>
                  <div class="unit">亩</div>
                </el-form-item>
                <div class="tips" v-if="cultivatedArea2">
                  <span> (其中新村通平台服务{{ cultivatedArea2 }}亩)</span>
                  <span style="color: #64addc; padding-left: 20px" @click="detailClick(1)">查看详情</span>
                </div>
              </div>
              <div class="Left">
                <el-form-item label="病虫害统防统治面积：" prop="diseaseControl">
                  <el-input :disabled="true" v-model="form.diseaseControl" type="number" placeholder=""></el-input>
                  <div class="unit">亩</div>
                </el-form-item>
                <div class="tips" v-if="diseaseControl2">
                  <span> (其中新村通平台服务{{ diseaseControl2 }}亩)</span>
                  <span style="color: #64addc; padding-left: 20px" @click="detailClick(2)">查看详情</span>
                </div>
              </div>
              <div class="Left">
                <el-form-item label="机收面积：" prop="receiveArea">
                  <el-input :disabled="true" v-model="form.receiveArea" placeholder="" type="number"></el-input>
                  <div class="unit">亩</div>
                </el-form-item>
                <div class="tips" v-if="receiveArea2">
                  <span> (其中新村通平台服务{{ receiveArea2 }}亩)</span>
                  <span style="color: #64addc; padding-left: 20px" @click="detailClick(3)">查看详情</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 其他资料 -->
          <div class="OtherAccessories">
            <div class="title">
              <p>承包耕地证明</p>
            </div>
            <div class="textContent">
              <!-- accept="|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z" -->
              <el-upload ref="pic1" multiple class="upload-demo" :limit="6" :action="uploadUrl" name="files"
                :auto-upload="false" :on-remove="handleRemove" :on-change="handleChangeUp" :file-list="fileList"
                :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>

              <div class="Item" v-for="(item, index) in otherFiles" :key="index">
                <div class="del" @click="del(index)">X</div>
                <el-image :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl" @click="AddFiles(item)">
                  <div slot="error" class="image-slot">
                    <!-- <svg t="1702953208684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3250" width="32" height="32"><path d="M293.7 635.7H643V592H293.7v43.7z m291.1-247.4H293.7V432h291.1v-43.7z m320.1-203.7v43.7l-43.6-43.6v1.2L816.4 141h1.1l-43.4-43.4 116.3-0.3h-684v87.3h-87.3v742.2h698.5v-87.3h87.3V184.6z m-654.8-43.7h508.3l-6.1 6.1 37.5 37.5H250.1v-43.6z m523.8 742.2H162.7V228.2h495l-4.6 4.6 120.8 120.8v529.5z m43.7-567.6L686.7 184.9l130.8-0.3v130.9z m43.7 480.3h-43.7V212.4l37.5 37.5 6.1-6.1v552z m-567.6-262H643v-43.7H293.7v43.7z m0 203.8H643v-43.7H293.7v43.7z" p-id="3251"></path></svg> -->
                    <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
                  </div>
                </el-image>
                <span>
                  {{ item.FilesName }}
                </span>
              </div>
            </div>
          </div>
          <!-- 保存 -->
          <div class="footer">
            <el-button type="primary" @click="submitForm()">提交</el-button>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
  import VueQr from "vue-qr";
  const Url = window.globalUrl.api1;
  import { mapGetters } from "vuex";
  import { socialAllowanceColumn } from "./tableColumn.js";
  import { uploadFile } from "@/api/fileApi";
  import { getAreaTree } from "@/api/certificate";
  import { removeEmptyChildren } from "@/js/utils/util";
  import TablePage from "@/components/table/table-page";
  import {
    saveSubsidyMainDeclare,
    deleteSubsidyMainDeclare,
    selectServiceArea,
  } from "@/api/subsidy";

  export default {
    data() {
      return {
        form: {
          applyUser: "", //申请人
          phone: "", //联系电话
          idCard: "", //身份证号
          areaCode: "", //地址
          bankCard: "", //银行账户
          address: "", //地址名
          cultivatedArea: "", //机耕面积
          aircraftArea: "", //机种面积
          diseaseControl: "", //病虫害统防统治面积
          receiveArea: "", // 机收面积
        }, //这是输入基础信息
        types: [
          {
            label: "集体经济组织",
            value: "3",
          },
          {
            label: "农业企业",
            value: "41",
          },
          {
            label: "家庭农场",
            value: "42",
          },
          {
            label: "合作社",
            value: "43",
          },
        ],
        props: {
          // label: "areaName",
          // children: "children",
          // value: "id",
          // fullPathName: "fullPathName",
          // checkStrictly: true,
          // emitPath: false,
          // expandTrigger: "hover",
          label: "areaName",
          children: "children",
          value: "id",
          checkStrictly: true,
        }, //省市区选择器
        formRules: {
          cultivatedArea: [
            { required: true, message: "请输入机耕面积", trigger: "blur" },
          ],
          aircraftArea: [
            { required: true, message: "请输入机种面积", trigger: "blur" },
          ],
          diseaseControl: [
            {
              required: true,
              message: "请输入病虫害统防统治面积",
              trigger: "blur",
            },
          ],
          receiveArea: [
            { required: true, message: "请输入机收面积", trigger: "blur" },
          ],
        }, //表单验证规则
        rules: {},
        show: false,
        innerVisible: false,
        serverAreaList: [], //服务面积表
        fileList: [], //上传图片的列表,
        uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
        baseUrl: window.globalUrl.BASE_API,
        proviceArr: [], //省市区列表
        userCardFrontImg: "", // 存储人面像图片
        userCardBackImg: "", // 存储国徽像图片
        otherFiles: [], //承包耕地证明
        tableData: [], //详情列表
        dialogVisible: false,
        columns: socialAllowanceColumn,
        declareId: "",
        cultivatedArea2: "", //机耕面积
        aircraftArea2: "", //机种面积
        diseaseControl2: "", //病虫害统防统治面积
        receiveArea2: "", // 机收面积
        innerBox: {}, //详情列表
      };
    },
    components: {
      TablePage,
      VueQr,
    },
    created() {
      this.initFrom();
    },
    mounted() {
      this.getRegions();
      this.selectServiceArea();
    },
    computed: {
      ...mapGetters(["identityObj", "topSelectAreacode"]),
    },
    methods: {
      callApiOnBlur() {
        let identityObj = JSON.parse(localStorage.getItem("identityObj"));
        this.text = `${Url}?declareId=${this.declareId}/identityId=${identityObj.content.identityId}`;
        console.log(this.text);
      },
      // 获取行政区树
      async getRegions() {
        let param = {
          level: "5",
          // parentCode:this.topSelectAreacode
        };
        let res = await getAreaTree(param);
        // console.log("行政区",res);
        let { code, data, msg } = res;
        if (code == 0) {
          this.proviceArr = removeEmptyChildren(data);
        } else {
          this.$message.error(msg);
        }
      },
      //行政区做切换
      regionChange() {
        let node = this.$refs.address.getCheckedNodes()[0];
        console.log(node, "查看node");
        this.areaHasChild = node.hasChildren;
        console.log(node.hasChildren);
        console.log(this.areaHasChild, "切换行政区");
        console.log(this.identityObj.areaCode);
        this.form.areaCode = node.value;
        this.form.address = node.pathLabels.join("");
      },
      // 回显
      initFrom() {
        this.form.address = this.identityObj.areaFullName;
        this.form.applyUser =
          this.identityObj.bossName || this.identityObj.contactName;
        this.form.phone =
          this.identityObj.contactPhone || this.identityObj.userName;
        this.form.idCard = this.identityObj.identityCode;
        this.form.areaCode = this.identityObj.areaCode;
        this.form.bankCard = this.identityObj.bankCardId;
        this.userCardFrontImg = this.identityObj.userCardFrontImg; // 存储人面像图片
        this.userCardBackImg = this.identityObj.userCardBackImg; // 存储国徽像图片
      },
      handleRemove(file, fileList) {
        this.agriculturalMachineryInvoiceUrl = fileList;
      },
      async handleChangeUp(file, fileList) {
        let param = new FormData();
        param.append("catalog", "financeAdd");
        param.append("file", file.raw);
        let res = await uploadFile(param);
        // console.log(res, "这是res");
        // let imgUrl = "/api/admin/file/get?ossFilePath=" + res.data.url;
        let FilesName = res.data.sourceFileName;
        let imgUrl = res.data.url;
        let item = {
          FilesName: FilesName,
          imgUrl: imgUrl,
        };
        this.otherFiles.push(item);
        console.log(this.otherFiles, "查看传的是什么");
      },
      //查看项目详情
      showDetail(row) {
        this.innerVisible = true;
        console.log(row, "查看row");
        this.innerBox = row;
        let pzzp = [];
        if (row.landCertificateUrl) {
          JSON.parse(row.landCertificateUrl).forEach((item) => {
            pzzp.push({
              ...item,
              urlView: this.baseUrl + "admin/file/get?ossFilePath=" + item.url,
            });
          });
        }
        row.pzzp = pzzp;
        let xczp = [];
        if (row.livePhotos) {
          JSON.parse(row.livePhotos).forEach((item) => {
            xczp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
          });
        }
        row.xczp = xczp;
        this.currentItem = row;
        console.log(row);
      },
      // 社会化服务补贴面积
      async selectServiceArea() {
        let params = {
          areaCode: this.form.areaCode,
        };
        let res = await selectServiceArea(params);
        console.log(res, "res");
        this.serverAreaList = res.data;

        // this.platformServiceArea=res.data.landAreaMu
        this.aircraftArea2 = res.data.aircraftArea; //机耕面积
        this.cultivatedArea2 = res.data.cultivatedArea; //机种面积
        this.diseaseControl2 = res.data.diseaseControl; //病虫害统防统治面积
        this.receiveArea2 = res.data.receiveArea; //机收面积
      },
      // 社会化服务面积详情
      detailClick(type) {
        console.log(type, "查看类型");
        this.dialogVisible = true;
        switch (type) {
          case 0:
            this.tableData = this.serverAreaList.aircraftAreaList;
            break;
          case 1:
            this.tableData = this.serverAreaList.cultivatedAreaList;
            break;
          case 2:
            this.tableData = this.serverAreaList.diseaseControlList;
            break;
          case 3:
            this.tableData = this.serverAreaList.receiveAreaList;
            break;
        }
      },
      // 清空表单
      async clearForm() {
        console.log("清空表单");
        let res = await deleteSubsidyMainDeclare({ id: this.declareId });
        this.show = false;
        // this.idea = "";
        this.text = "";
        this.declareId = "";
      },
      //保存
      async submitForm() {
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            //校验成功
            let subsidyId = this.$route.query.subsidyId;
            let subsidyType = this.$route.query.subsidyType;
            let params = {
              areaCode: this.identityObj.areaCode,
              areaName: this.identityObj.areaFullName,
              declareIdentityId: this.identityObj.identityId,
              declareType: this.identityObj.identityTypeCode,
              declareUserId: this.identityObj.userId,
              subsidyId: subsidyId,
              subsidyType: subsidyType,
              socialServiceDto: {
                ...this.form,
                otherFiles: JSON.stringify(this.otherFiles),
              },
            };
            this.userCardFrontImg
              ? (params.socialServiceDto.legalIdcardPicFront = this.userCardFrontImg)
              : null;
            this.userCardBackImg
              ? (params.socialServiceDto.legalIdcardPicBack = this.userCardBackImg)
              : null;
            console.log(params, "params");
            let res = await saveSubsidyMainDeclare(params);
            if (res.code == 0) {
              this.declareId = res.data;
              this.show = true;
              this.callApiOnBlur();
            } else {
              this.$message.error(res.msg);
            }
          } else {
            this.$message.error("请完善发布信息");
          }
        });
      },
      // 下载
      download(item) {
        const url = item.urlView;
        let dom = document.createElement("a");
        dom.style.display = "none";
        dom.href = url;
        dom.setAttribute("download", item.name);
        document.body.appendChild(dom);
        dom.click();
      },
    },
  };
</script>

<style lang="less" scoped>
  .tongyi {
    box-sizing: border-box;

    .topbox {
      span {
        width: 30%;
        font-size: 20px;
        // font-weight: 400;
        color: #000;
      }

      input {
        width: 60%;
        font-size: 20px;
      }
    }

    .footerbox {
      width: 100%;
      text-align: center;
      margin-top: 20px;

      .img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .socialAllowance {
    width: 100%;
    display: flex;
    justify-content: center;
    background: white;

    .subsidyBox {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;

      margin-top: 50px;

      .LoanApplication {
        width: 100%;
        height: 100%;

        /*基础信息*/
        .BasicInformation {
          display: flex;
          border: solid 1px #cccc;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;
            }
          }

          .textContent {
            width: 88%;
            display: flex;
            padding: 10px;

            .Left {
              width: 50%;
              height: 100%;

              /deep/.el-form-item {
                width: 100%;

                .el-input {
                  width: 200px;
                }

                .el-form-item__label {
                  width: 130px;
                  height: 40px;
                }
              }
            }

            .Right {
              width: 50%;
              height: 100%;

              /deep/.el-form-item {
                width: 100%;

                .el-input {
                  width: 200px;
                }

                .el-form-item__label {
                  width: 124px;
                  height: 40px;
                }
              }
            }
          }
        }

        /*补贴面积*/
        .SubsidizedArea {
          display: flex;
          border: solid 1px #cccc;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;
            }
          }

          .textContent {
            width: 88%;
            display: flex;
            padding: 10px;
            width: 100%;
            display: flex;
            flex-flow: wrap;

            .Left {
              width: 50%;
              display: flex;
              flex-direction: column;
              justify-content: center;

              /deep/.el-form-item {
                display: flex;
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 165px !important;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }
              }

              .tips {
                padding-top: 25px;
                padding-left: 110px;
                /*  */
              }
            }

            .Center {
              width: 33%;
              height: auto;
              display: flex;
              flex-direction: column;
              justify-content: center;

              /deep/.el-form-item {
                display: flex;
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 120px !important;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }
              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }

            .Right {
              width: 33%;
              height: auto;
              display: flex;
              flex-direction: column;
              justify-content: center;

              /deep/.el-form-item {
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-form-item__label {
                  width: 120px !important;
                }
              }

              /deep/.el-form-item {
                display: flex;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 100px;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }
              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }
          }
        }

        /*种植信息*/
        .PlantingArea {
          display: flex;
          border: solid 1px #cccc;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;
            }
          }

          .textContent {
            width: 100%;
            display: flex;
            padding: 10px;

            .Left {
              width: 33%;
              display: flex;
              flex-direction: column;
              justify-content: center;

              /deep/.el-form-item {
                display: flex;
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 120px !important;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }
              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }

            .Center {
              width: 33%;
              display: flex;
              flex-direction: column;

              /deep/.el-form-item {
                display: flex;
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 120px !important;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }
              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }

            .Right {
              width: 33%;
              display: flex;
              flex-direction: column;

              /deep/.el-form-item {
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-form-item__label {
                  width: 120px !important;
                }
              }

              /deep/.el-form-item {
                display: flex;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 100px;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }
              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }
          }
        }

        /* 身份证 */
        .LegalPersonIDCard {
          display: flex;

          /deep/.el-image {
            img {
              width: 200px;
              height: 100px;
              padding-left: 20px;
            }

            .image-slot {
              img {
                width: 100px;
                height: 80px;
              }
            }
          }
        }

        /*其他附件*/
        .OtherAccessories {
          border: solid 1px #ccc;
          display: flex;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;

              i {
                color: red;
              }
            }
          }

          .textContent {
            width: 100%;
            height: 120px;
            display: flex;
            border: solid 1px #ccc;

            .Item {
              width: 150px;
              height: 100%;
              display: flex;
              flex-direction: column;
              text-align: center;
              position: relative;
              padding: 10px;

              .el-image {
                width: 150px;
                height: 85px;
                background: #e6e3e3;

                img {
                  width: 200px;
                  height: 50px;
                }

                i {
                  font-size: 50px;
                  font-weight: bold;
                  margin-top: 20px;
                }
              }

              span {
                margin-top: 5px;
              }

              .del {
                width: 20px;
                height: 20px;
                border: solid 1px black;
                border-radius: 50px;
                display: none;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 5000;
                text-align: center;
                line-height: 20px;
              }
            }

            .Item:hover {
              .del {
                display: block;
              }
            }

            .upload-demo {
              i {
                font-size: 50px;
                font-weight: bold;
                margin-top: 30px;
              }
            }
          }
        }

        /* 提交申报*/
        .footer {
          margin: 20px 0px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .innerBox {
    /deep/.el-form-item {
      margin-bottom: 0 !important;

      .el-input {
        width: 260px;
      }
    }

    /deep/.el-dialog__body {
      padding-left: 30px;
    }

    .pdfStyle {
      margin: 0;
      background-color: #f5f7fa;
      color: #409eff;
      margin-bottom: 4px;
      height: 32px;
      width: 90%;
      line-height: 32px;
      text-indent: 8px;

      i {
        cursor: pointer;
        float: right;
        font-size: 20px;
        margin: 6px;
      }
    }
  }
</style>
